import React, { useEffect, useState, useRef } from 'react'

import "./LectureHead.css"
import "../../global.css"

function ProblemTitle({ data, vs }) {
    return (
        <div className="lecture-head-background">
            <div className="slide-div">
                <div className="slide-header">
                    <div className="problem-title">
                        {data.title}
                    </div>
                </div>
                <div className="slide-content">
                    {vs}
                </div>
            </div >
        </div>
    )
}



export default ProblemTitle