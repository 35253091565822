import React, { useState, useRef } from 'react'
import "./Problems.css"
import { Container, Row, Col } from "react-bootstrap"

import PdfButton from '../../elements/MyButton/PdfButton'
import { SearchBar, TagBar } from "../../elements/SearchBar/SearchBar"
import Switch from "../../elements/Switch/Switch"
import PdfData from '../../data/PdfData'
import { ContestData, BranchData, InfoTagData } from '../../data/TagData'
import { ScrollToTopOnMount } from '../../functions/ScrollToTop'
import { publicTime } from "../../functions/myTime"
import useCookie from '../../functions/useCookie'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { BsSquare, BsArrowUpSquareFill, BsArrowDownSquareFill } from 'react-icons/bs'
import { toPath } from "../../functions/toPath"
import NotFoundPage from '../NotFoundPage/NotFoundPage'

import Thumbnail from '../../elements/Thumbnail/Thumbnail'
import ChannelBanner from '../../elements/ChannelBanner/ChannelBanner'
import Endcard from '../../elements/Endcard/Endcard'
import { getUniqueId } from '../../functions/getUniqueId'
import ProblemTitle from '../../elements/LectureHead/ProblemTitle'

function Problems({ admin, customDate, date, navbarActive, showThumbnail, showChannelDetails }) {
    const location = useLocation()
    const navigate = useNavigate()

    const didMount = useRef(0)

    const getOnClick = (dataClass, tag) => {
        if (dataClass === "Contest") return addToContests(tag)
        else if (dataClass === "Branch") return addToBranches(tag)
        else if (dataClass === "Info") return addToInfoTags(tag)
        else if (dataClass === "Difficulty") return addToDiff(tag)
        else if (dataClass === "Year") return addToYear(tag)
        else return 0
    }

    const getOnClickRemove = (dataClass, tag) => {
        if (dataClass === "Contest") return removeFromContests(tag)
        else if (dataClass === "Branch") return removeFromBranches(tag)
        else if (dataClass === "Info") return removeFromInfoTags(tag)
        else return 0
    }

    const timeCompare = (a, b) => {
        if (b.releaseTime === undefined) {
            return -1
        }
        if (a.releaseTime === undefined) {
            return 1
        }
        return a.releaseTime - b.releaseTime
    }

    const toContest = (tags) => {
        return (Object.keys(ContestData).map((key) => ContestData[key]).filter((contest) => tags.includes(contest.tag)))
    }

    const addToContests = (tag) => {
        if (!contests.includes(tag)) {
            setContests([...contests, tag], 12)
        }
    }

    const removeFromContests = (tag) => { setContests(contests.filter(e => e !== tag)) }

    const toBranch = (tags) => {
        return (Object.keys(BranchData).map((key) => BranchData[key]).filter((branch) => tags.includes(branch.tag)))
    }

    const addToBranches = (tag) => {
        if (!branches.includes(tag)) {
            setBranches([...branches, tag], 12)
        }
    }

    const removeFromBranches = (tag) => { setBranches(branches.filter(e => e !== tag)) }

    const toInfoTag = (tags) => {
        return (Object.keys(InfoTagData).map((key) => InfoTagData[key]).filter((infoTag) => tags.includes(infoTag.tag)))
    }

    const addToInfoTags = (tag) => {
        if (!infoTags.includes(tag)) {
            setInfoTags([...infoTags, tag], 12)
        }
    }

    const removeFromInfoTags = (tag) => { setInfoTags(infoTags.filter(e => e !== tag)) }

    const addToDiff = (tag) => {
        const diff = parseInt(tag)
        setLoDiff(diff - 1)
        setHiDiff(diff)
    }

    const addToYear = (tag) => {
        const year = parseInt(tag)
        setLoYear(year - 1)
        setHiYear(year)
    }

    const [activeMenu, setActiveMenu] = useCookie("activeMenu", false)

    const defaultLoDiff = 0
    const defaultHiDiff = 20
    const defaultLoYear = 1975
    const defaultHiYear = 2024

    const [loDiff, setLoDiff] = useCookie("loDiff", defaultLoDiff)
    const [hiDiff, setHiDiff] = useCookie("hiDiff", defaultHiDiff)
    const [loYear, setLoYear] = useCookie("loYear", defaultLoYear)
    const [hiYear, setHiYear] = useCookie("hiYear", defaultHiYear)
    const [contests, setContests] = useCookie("contests", [])
    const [branches, setBranches] = useCookie("branches", [])
    const [infoTags, setInfoTags] = useCookie("infoTags", [])
    const [sortBy, setSortBy] = useCookie("sortBy", "anti-release")

    useEffect(() => {
        if (loDiff < defaultLoDiff) {
            setLoDiff(defaultLoDiff)
        }
        if (hiDiff > defaultHiDiff) {
            setHiDiff(defaultHiDiff)
        }
        if (loYear < defaultLoYear) {
            setLoYear(defaultLoYear)
        }
        if (hiYear > defaultHiYear) {
            setHiYear(defaultHiYear)
        }
        if (["release", "anti-release", "difficulty", "anti-difficulty", "alphabetical", "anti-alphabetical"].includes(sortBy)) {
            setSortBy("anti-release")
        }
    }, [])

    const [filteredPdfData, setFilteredPdfData] = useState([])
    const [showMoreId, setShowMoreId] = useState(-1)
    const [activeId, setActiveId] = useState(-1)

    const [throw404, setThrow404] = useState(false)

    const resetFilters = () => {
        setLoDiff(defaultLoDiff)
        setHiDiff(defaultHiDiff)
        setLoYear(defaultLoYear)
        setHiYear(defaultHiYear)
        setContests([])
        setBranches([])
        setInfoTags([])
    }

    const pdfRefs = useRef({})

    useEffect(() => {
        setActiveId(showMoreId)
    }, [showMoreId])

    useEffect(() => {
        if (showMoreId !== -1) {
            const offset = 85
            const elementPosition = pdfRefs.current[showMoreId].getBoundingClientRect().top
            const offsetPosition = elementPosition + window.pageYOffset - offset
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }, [activeId])

    const searchBarProps = {
        admin: admin,
        loDiff: loDiff,
        setLoDiff: setLoDiff,
        hiDiff: hiDiff,
        setHiDiff: setHiDiff,
        loYear: loYear,
        setLoYear: setLoYear,
        hiYear: hiYear,
        setHiYear: setHiYear,
        contests: contests,
        setContests: setContests,
        addToContests: addToContests,
        branches: branches,
        setBranches: setBranches,
        addToBranches: addToBranches,
        infoTags: infoTags,
        setInfoTags: setInfoTags,
        addToInfoTags: addToInfoTags,
        getOnClick: getOnClick,
        getOnClickRemove: getOnClickRemove,
        toContest: toContest,
        toBranch: toBranch,
        toInfoTag: toInfoTag,
        defaultLoDiff: defaultLoDiff,
        defaultHiDiff: defaultHiDiff,
        defaultLoYear: defaultLoYear,
        defaultHiYear: defaultHiYear,
    }

    const toggleMenu = () => setActiveMenu(!activeMenu, 12)

    const updateShowMoreId = (id) => {
        if (id === showMoreId) {
            setShowMoreId(-1)
        } else {
            setShowMoreId(id)
        }
    }

    const getUnfilteredPdfData = () => {
        return Object.keys(PdfData).map(key => {
            return PdfData[key]()
        }).filter(file => {
            return publicTime(customDate, date, file.releaseTime)
        })
    }

    const getUpdatedFilteredPdfData = () => {
        let unsortedPdfData = getUnfilteredPdfData().filter(file => {
            return (file.difficulty.tag > loDiff) && (file.difficulty.tag <= hiDiff)
        }).filter(file => {
            return (file.year.tag > loYear) && (file.year.tag <= hiYear)
        }).filter(file => {
            if (contests.length === 0) return true;
            else return contests.includes(file.contest.tag)
        }).filter(file => {
            if (branches.length === 0) return true;
            else return branches.includes(file.branch.tag)
        }).filter(file => {
            if (infoTags.length === 0) return true;
            else return infoTags.some(tag => file.infoTags.map(infoTag => infoTag.tag).includes(tag))
        })
        return sortedPdfData(unsortedPdfData)
    }

    const sortedPdfData = (unsortedPdfData) => {
        if (sortBy === "alphabetical") {
            return unsortedPdfData.sort((a, b) => {
                return a.fileName.toLowerCase().localeCompare(b.fileName.toLowerCase())
            })
        } else if (sortBy === "anti-alphabetical") {
            return unsortedPdfData.sort((a, b) => {
                return b.fileName.toLowerCase().localeCompare(a.fileName.toLowerCase())
            })
        } else if (sortBy === "release") {
            return unsortedPdfData.sort((a, b) => {
                let d = timeCompare(a, b)
                return d
            })
        } else if (sortBy === "anti-release") {
            return unsortedPdfData.sort((a, b) => {
                return timeCompare(b, a)
            })
        } else if (sortBy === "difficulty") {
            return unsortedPdfData.sort((a, b) => {
                return b.difficulty.tag - a.difficulty.tag
            })
        } else if (sortBy === "anti-difficulty") {
            return unsortedPdfData.sort((a, b) => {
                return a.difficulty.tag - b.difficulty.tag
            })
        }
    }

    useEffect(() => {
        setFilteredPdfData(getUpdatedFilteredPdfData())
    }, [loDiff, hiDiff, loYear, hiYear, contests, branches, infoTags, date, customDate, sortBy])

    useEffect(() => {
        if (didMount.current > 1) {
            setShowMoreId(-1)
        }
        else {
            didMount.current += 1
        }
    }, [activeMenu, loDiff, hiDiff, loYear, hiYear, contests, branches, infoTags, date, customDate, navbarActive, sortBy])

    useEffect(() => {
        const subpath = location.pathname.slice(20)

        if (subpath === "") {
            return
        }

        const pdf = getUnfilteredPdfData().find(pdf => {
            return toPath(pdf.title) === subpath
        })

        if (pdf === undefined) {
            setThrow404(true)
            return
        }

        resetFilters()
        setShowMoreId(getUniqueId(pdf))
    }, [])

    const [pdfButtons, setPdfButtons] = useState([])

    useEffect(() => {
        if (filteredPdfData.length === 0)
            setPdfButtons(<div>
                No Solution matches the applied filters.
            </div>)
        else {
            setPdfButtons(filteredPdfData.map(file => {
                return (
                    <Col lg="6" key={file.id} className="pdf-col">
                        <PdfButton admin={admin} id={file.id} showMoreId={showMoreId} setShowMoreId={setShowMoreId} file={file} getOnClick={getOnClick} problemSearch={true} problemDelete={false} />
                    </Col>
                )
            }))
        }
    }, [filteredPdfData])

    useEffect(() => {
        document.title = "Calimath - Problems and solutions"
    }, [])

    return (throw404 ? <NotFoundPage /> :
        <Container fluid>
            {admin ? <></> : <ScrollToTopOnMount />}
            <Row>
                <Col xs="12">
                    <div className="content-box">
                        <Row>
                            <h1 className="problems-title-col">
                                Problems and solutions
                            </h1>
                        </Row>
                        <Row>
                            <div id="options-row">
                                <div className="switch-box">
                                    <div className="info-div">
                                        {activeMenu ? (
                                            <>
                                                Close search menu
                                            </>
                                        ) : (
                                            <>
                                                Open search menu
                                            </>
                                        )}
                                    </div>
                                    <Switch active={activeMenu} toggle={toggleMenu} />
                                </div>
                                <div id="order-div">
                                    <div className="order-by">
                                        Sort by release date
                                        <div className="order-icon-div" onClick={() => {
                                            if (sortBy === "anti-release") {
                                                setSortBy("release")
                                            } else {
                                                setSortBy("anti-release")
                                            }
                                        }}>
                                            {sortBy === "release"
                                                ?
                                                <BsArrowUpSquareFill className="order-icon" />
                                                :
                                                (sortBy === "anti-release"
                                                    ?
                                                    <BsArrowDownSquareFill className="order-icon" />
                                                    :
                                                    <BsSquare className="order-icon" />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="order-by">
                                        Sort alphabetically
                                        <div className="order-icon-div" onClick={() => {
                                            if (sortBy === "alphabetical") {
                                                setSortBy("anti-alphabetical")
                                            } else {
                                                setSortBy("alphabetical")
                                            }
                                        }}>
                                            {sortBy === "alphabetical"
                                                ?
                                                <BsArrowUpSquareFill className="order-icon" />
                                                :
                                                (sortBy === "anti-alphabetical"
                                                    ?
                                                    <BsArrowDownSquareFill className="order-icon" />
                                                    :
                                                    <BsSquare className="order-icon" />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="order-by">
                                        Sort by difficulty
                                        <div className="order-icon-div" onClick={() => {
                                            if (sortBy === "anti-difficulty") {
                                                setSortBy("difficulty")
                                            } else {
                                                setSortBy("anti-difficulty")
                                            }
                                        }}>
                                            {sortBy === "difficulty"
                                                ?
                                                <BsArrowUpSquareFill className="order-icon" />
                                                :
                                                (sortBy === "anti-difficulty"
                                                    ?
                                                    <BsArrowDownSquareFill className="order-icon" />
                                                    :
                                                    <BsSquare className="order-icon" />
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Col>
            </Row>
            {!activeMenu ? <></> :
                <Row>
                    <Col>
                        <SearchBar {...searchBarProps} />
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <TagBar {...searchBarProps} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="content-box">
                        <Row className="pdf-row">
                            {filteredPdfData.length === 0 ?
                                <div>
                                    No Solution matches the applied filters.
                                </div>
                                :
                                filteredPdfData.map(file => {
                                    return (
                                        <Col lg="6" xxl="4" key={file.id} className="pdf-col">
                                            <PdfButton admin={admin} id={file.id} pdfRefs={pdfRefs} showMoreId={showMoreId} updateShowMoreId={updateShowMoreId} file={file} getOnClick={getOnClick} problemSearch={true} problemDelete={false} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </Col>
            </Row>
            {!showChannelDetails ? <></> :
                <Row>
                    <Col>
                        <ChannelBanner problems={filteredPdfData} />
                    </Col>
                    {Object.keys(BranchData).map(key => BranchData[key]).map(branch => {
                        return <Col>
                            <Endcard branch={branch} />
                        </Col>
                    })}
                </Row>
            }
            {!showThumbnail ? <></> :
                <>
                    {
                        filteredPdfData.map(file => {
                            return (
                                <Row>
                                    <Col key={"tn" + file.id} className="pdf-col">
                                        <Thumbnail data={file} />
                                    </Col>
                                    {(file.videoStatements === undefined) ? <></> :
                                        file.videoStatements.map(vs => {
                                            return < Col key={"head" + file.id} className="pdf-col">
                                                < ProblemTitle data={file} vs={vs} />
                                            </Col>
                                        })
                                    }
                                </Row>
                            )
                        })
                    }
                </>
            }
        </Container >
    )
}

export default Problems


